.adminEditCourse {
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
    main {
        width: 100%;
        height: 100%;
        padding: 30px 0;
        overflow: auto;
        background-color: #FDFDFD;
        div {
            margin-left: 5%;
            display: inline-block;
            .goback {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            color: gray;
            font-size: 22px;
            margin-bottom: 30px;
            transition: opacity 0.3s ease;
            img {
                margin-right: 12px;
                width: 30px;
            }
            &:hover {
                opacity: .4;
            }
            &:active {
                opacity: 1.3;
            }
        }
        }
        .screen-title {
            width: 90%;
            margin: auto;
            font-size: 38px;
            margin-bottom: 50px;
        }
        .container {
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .container-course-info {
            padding-bottom: 50px;
            section {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
                //border-bottom: 1px solid rgba($color: #9cacc7, $alpha: 1);
                //padding-bottom: 40px;
                margin-bottom: 40px;
                .section-title {
                    color: #1E7213;
                    font-size: 18px;
                    font-weight: 400;
                }
                .contenido-tematico {
                    display: flex;
                    flex-direction: column;
                    label {
                        color : #868686;
                        font-size: 14px;
                        margin-bottom: 12px;
                    }
                    .button-container {
                        width: 100%;
                        margin: 0;
                        button {
                            background-color: #004080;
                            color: white;
                            appearance: none;
                            border: 0;
                            outline: none;
                            font-size: 16px;
                            font-weight: 600;
                            padding: 8px 22px;
                            border-radius: 5px;
                            transition: background-color 0.3s ease;
                            &:hover {
                                background-color: #1e5b98;
                            }
                            &:active {
                                background-color: #082645;
                            }
                        }
                    }
                    .topics {
                        width: 100%;
                        margin: 0;
                        margin-top: 20px;
                        padding: 0;
                        padding: 20px 0;
                        display: flex;
                        flex-direction: row;
                        overflow-x: auto;
                        .topic-card {
                            margin: 0;
                            margin-right: 20px;
                            padding: 20px;
                            background-color: white;
                            display: flex;
                            flex-direction: column;
                            min-width: 520px;
                            border-radius: 5px;
                            -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                            -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                            box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                            span {
                                color: #4B4B4B;
                                font-size: 12px;
                            }
                            input {
                                margin-bottom: 18px;
                                font-size: 16px;
                                color: rgb(67, 67, 67);
                            }
                            .buttons {
                                width: 100%;
                                margin: 0;
                                outline: none;
                                button {
                                    background-color: transparent;
                                    appearance: none;
                                    border: 0;
                                    color: #AC96A1;
                                    font-size: 16px;
                                }
                            }
                        }
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    
                }
                .section-content {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .inputRowCourse {
                        display: flex;
                        flex-direction: column;
                        margin: 30px 0;
                        label {
                            color : #868686;
                            font-size: 14px;
                        }
                        input {
                            font-size: 18px;
                            appearance: none;
                            outline:none;
                            padding-top: 12px;
                            padding-bottom: 4px;
                            border: 0;
                            border-bottom: 1px solid #EBEBEB;
                            background-color: transparent;
                        }
                    }
                    .course-row-50 {
                        width: 45%;
                    }
                    .course-row-100 {
                        width: 100%;
                    }
                }
            }
            .btn-actualizar-curso{
                background-color: #64E36E;
                color: white;
                appearance: none;
                border: 0;
                outline: none;
                font-size: 18px;
                font-weight: 600;
                padding: 12px 32px;
                border-radius: 5px;
                transition: background-color 0.3s ease;
                &:hover {
                    background-color: #2EB739;
                }
                &:active {
                    background-color: #008E0B;
                }
            }
        }



    }
}

}

@media only screen and (max-width : 500px) {
    .adminEditCourse {
        .content {
            flex-direction: column;
            main {
                .container {
                }
            }
        }
    }
}