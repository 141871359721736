.userDialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: rgb(5, 22, 43), $alpha: .6);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-container {
        width: 90%;
        margin: auto;
        background-color: white;
        -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
        -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
        box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
        border-radius: 8px;
        padding: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        .close-icon {
            font-size: 14px;
            position: absolute;
            top: 0;
            right: 0;
            padding: 6px 12px;
            background-color: rgb(240, 103, 103);
            color: white;
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover {
                background-color: rgb(218, 53, 53);
            }
            &:active {
                background-color: rgb(228, 137, 137);
            }
        }
        h2 {
            font-size: 32px;
            width: 100%;
            color: rgb(6, 43, 85);
            margin-bottom: 12px;
        }
        .row-cursos {
            width: 100%;
            span.curso {
                margin-top: 8px;
                font-size: 16px;
                color: black;
                display: flex;
                align-items: center;
                img {
                    width: 16px;
                    margin-right: 4px;
                }
            }
        }
        .row {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
            span {
                color: rgba($color: #4B4B4B, $alpha: .43);
                font-size: 14px;
            }
            input {
                border: 0;
                outline: none;
                appearance: none;
                background-color: white;
                font-size: 16px;
                padding: 10px 0;
            }
        }
        .row-50 {
            width: 45%;

        }
    }
}

.loadingDialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: rgb(5, 22, 43), $alpha: .6);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-container {
        margin: auto;
        background-color: white;
        -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
        -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
        box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
        border-radius: 8px;
        padding: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .close-icon {
            font-size: 14px;
            position: absolute;
            top: 0;
            right: 0;
            padding: 6px 12px;
            background-color: rgb(240, 103, 103);
            color: white;
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover {
                background-color: rgb(218, 53, 53);
            }
            &:active {
                background-color: rgb(228, 137, 137);
            }
        }
        h2 {
            font-size: 32px;
            width: 100%;
            color: rgb(6, 43, 85);
            margin-bottom: 32px;
        }
        span.icon {
            margin-top: 20px;
            color: gray;
        }
    }
}

@media only screen and (max-width:752px){
    .userDialog {
        .dialog-container {
            .row-50 {
                width: 100%;
            }
        }
    }
}