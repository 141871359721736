@import "./variables.scss";

.globalContainer {
    //background-color: red;
    height: 100%;
    height: 100vh;
    //overflow: auto;
    position: relative;
}

.main {
    //background-color: red;
    height: 93%;
    display: flex;
    transition: flex-direction 0.3s ease;

    .select-log-option {
        width: 50%;
        background-image: url("./../../assets/img/login-background.jpg");
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: relative;
        span.citysoft {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 12px;
            font-weight: 300;
            opacity: 0.4;
            a {
                font-size: 14px;
                text-decoration: none;
                outline: none;
                appearance: none;
                color: white;
                transition: all 0.3s ease;
                &:hover {
                    color: rgb(5, 134, 255);
                }
            }
        }
        span {
            color: white;
            font-weight: 700;
            font-size: 36px;
            margin-bottom: 20px;
            text-align: center;
        }
        button {
            background-color: black;
            border: none;
            border-radius: 8px;
            appearance: none;
            color: white;
            font-size: 16px;
            padding: 8px 32px;
            margin-bottom: 40px;
            &:hover {
                color: rgba($color: white, $alpha: .6);
                transition: all 0.3s ease;
            }
        }
    }

    .log-option {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px;
        overflow-y: auto;

            .title {
                color: #2CC388;
                font-size: 42px;
                margin-bottom: 8px;
            }
            .description {
                color: #868686;
                font-size: 14px;
            }
            form {
                //border: 1px solid green;
                width: 100%;
                margin: 30px 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                .row-100 {
                    width: 100%;
                }
                .row-50 {
                    width: 45%;
                }
                .inputRow {
                    display: flex;
                    flex-direction: column;
                    margin: 30px 0;
                    label {
                        color : #868686;
                        font-size: 14px;
                    }
                    input {
                        font-size: 18px;
                        appearance: none;
                        outline:none;
                        padding-top: 12px;
                        padding-bottom: 4px;
                        border: 0;
                        border-bottom: 1px solid #EBEBEB;
                    }
                }
                button {
                    background-color: #64E36E;
                    color: white;
                    appearance: none;
                    outline: none;
                    border: 0;
                    padding: 8px 42px;
                    font-size: 18px;
                    font-weight: 500;
                    border-radius: 5px;
                    transition: color 0.3s ease;
                    &:hover {
                        color: #269007;
                    }
                }
            }
        
    
    }
}

@media only screen and (max-width:926px) {
    .main {
        .select-log-option {
            width: 30%;
        }
    }
}

@media only screen and (max-width:690px) {
    .globalContainer{
        overflow-y: auto;
    }
    .main {
        flex-direction: row;
        flex-wrap: wrap;
        .select-log-option {
            width: 100%;
            padding: 20px 0;
            span, button {
                margin-bottom: 0;
            }
            span.citysoft {
                padding-bottom: 10px;
            }
        }
        .log-option {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 600px) {

    .main {
        .log-option {
            form {
                .row-50 {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 691px) {

    .main {
        .select-log-option {
            span.citysoft {
                display: none;
            }
        }
    }
}