.viewCourse {
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
    }
    main {
        width: 100%;
        height: 100%;
        //padding: 30px 0;
        overflow: auto;
        background-color: #FDFDFD;
        .cover {
            height: 300px;
            background-position: center;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .cover-content {
                border-left: 3px solid white;
                padding: 14px 20px;
                .cover-category {
                    span {
                        font-size: 12px;
                        color: white;
                        letter-spacing: 1px;
                    }
                    span.category{
                        letter-spacing: normal;
                        font-size: 18px;
                        color: white;
                        font-weight: 600;
                    }
                }
                .cover-title {
                    font-size: 38px;
                    color: white;
                    font-weight: bolder;
                }
                
            }
            img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 80px;
                opacity: 0.5;
                padding: 10px;
            }
            .goback {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
                color: white;
                font-size: 18px;
                margin: 30px;
                opacity: 0.5;
                transition: opacity 0.3s ease;
                img {
                    margin-right: 12px;
                    width: 30px;
                }
                &:hover {
                    opacity: 1;
                }
                &:active {
                    opacity: 1.3;
                }
            }
        }
        .banner-general-info {
            background-color: white;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 40px 10px;
            .info {
                width: auto;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .enroll-btn {
                    background-color: #64E36E;
                    text-decoration: none;
                    color: white;
                    appearance: none;
                    outline: none;
                    border:none;
                    padding: 8px 24px;
                    font-weight: 600;
                    border-radius: 5px;
                    transition: background-color 0.3s ease;
                    &:hover {
                        background-color: rgb(50, 163, 60);
                    }
                    &:active {
                        background-color: rgb(19, 131, 28);
                    }
                }
                span.info-title {
                    color: #2CC388;
                    margin-bottom: 14px;
                }
                span {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    img {
                        width: 20px;
                        margin-right: 4px;
                    }
                }
            }
        }
        .container {
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: column;
            .course-content-info {
                padding: 70px 0;
                section {
                    margin-bottom: 40px;
                    h2 {
                        color: #4E4D4D;
                        margin-bottom: 18px;
                        font-size: 28px;
                    }
                    p {
                        color: #575757;
                        line-height: 28px;
                    }
                    .topics {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        .topic {
                            background-color: white;
                            padding: 20px;
                            width: 30%;
                            margin-bottom: 24px;
                            max-width: 420px;
                            min-width: 320px;
                            -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                            -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                            box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                            border-radius: 8px;
                            h3 {
                                color: #0F1F31;
                                font-size: 18px;
                                font-weight: 500;
                                border-left: 3px solid green;
                                padding-left: 4px;
                                margin-bottom: 18px;
                            }
                            p {
                                color: #575757;
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }
                    }
                }
                .goAction {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .enroll-btn {
                        background-color: #64E36E;
                        text-decoration: none;
                        color: white;
                        font-size: 18px;
                        padding: 12px 32px;
                        font-weight: 600;
                        appearance: none;
                        outline: none;
                        border:none;
                        border-radius: 5px;
                        transition: background-color 0.3s ease;
                        &:hover {
                            background-color: rgb(50, 163, 60);
                        }
                        &:active {
                            background-color: rgb(19, 131, 28);
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width : 792px) {
    .viewCourse {
        .content {
            main {
                .container {
                    .course-content-info {
                        section {
                            .topics {
                                .topic {
                                    width: 100%;
                                    min-width: 100%;
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 500px) {
    .viewCourse {
        .content {
            flex-direction: column;
            main {
                .banner-general-info {
                    flex-direction: column;
                    padding: 0;
                    .info {
                        width: 100%;
                        padding: 20px 0;
                        border-bottom: 1px solid rgba(233, 233, 233, 0.687) ;
                    }
                }
            }
        }
    }
}