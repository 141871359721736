.loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
        font-size: 32px;
        font-weight: 400;
        color: rgb(186, 186, 186);
    }
    span {
        margin-top: 30px;
        color: #2D95FD;
        opacity: 0.6;
    }
}