.payScreen {
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
        main {
            width: 100%;
            height: 100%;
            padding: 30px 0;
            overflow: auto;
            background-color: white;
            div {
                margin-left: 5%;
                display: inline-block;
                .goback {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-decoration: none;
                    color: gray;
                    font-size: 22px;
                    margin-bottom: 30px;
                    transition: opacity 0.3s ease;
                    img {
                        margin-right: 12px;
                        width: 30px;
                    }
                    &:hover {
                        opacity: .4;
                    }
                    &:active {
                        opacity: 1.3;
                    }
                }
            }
            .screen-title {
                width: 90%;
                margin: auto;
                font-size: 38px;
                margin-bottom: 0px;
            }
            .container {
                width: 90%;
                margin: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .pay-buttons {
                    width: 100%;
                    margin: 0;
                    padding: 50px 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    position: relative;
                    z-index: 0;
                    .pay-buttons-title {
                        width: 100% !important;
                        font-weight: 500;
                        color: #71E293;
                        margin-bottom: 18px;
                    }
                    .pay-buttons-payment {
                        width: 30%;
                        max-width: 300px;
                        min-width: 260px;
                        margin: 0 10px 0 0;
                        box-sizing: border-box;
                        border-radius: 5px;
                        * {
                            width: 100%;
                            max-width: 300px;
                            margin: 0;
                            box-sizing: border-box;
                            right: 0;
                        }
                        button {
                            height: 93%;
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            border: 0;
                            background-color: #EFEFEF;
                            padding: 8px;
                            transition: background-color 0.3s ease-in-out;
                            appearance: none;
                            outline: none;
                            cursor: pointer;
                            &:hover {
                                background-color: #DEDEDE;
                            }
                            img {
                                width: 40%;
                            }
                        }
                    }
                }
                .to-pay-info {
                    width: 100%;
                    margin: 0;
                    padding: 50px 0;
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid rgba($color: #707070, $alpha: .23);
                    .to-pay-info-title {
                        font-weight: 500;
                        color: #71E293;
                        margin-bottom: 18px;
                    }

                    .to-pay-info-course, .to-pay-info-user {
                        background-color: white;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: wrap;
                        .presentation {
                            margin: 20px 0;
                            width: 24%;
                            min-width: 240px;
                            max-width: 340px;
                            padding: 20px;
                            padding-bottom: 70px;
                            background-position: center;
                            background-size: cover;
                            border-radius: 8px;
                            -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
                            -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
                            box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.4);
                            span {
                                color:white;
                                font-size: 12px;
                                letter-spacing: 1px;
                            }
                            p {
                                font-size: 16px;
                                color: white;
                                font-weight: 600;
                            }
                        }
                        .info-course-name, .info-course-price, .info-user-name {
                            margin-top: 20px;
                            margin-bottom: 20px;
                            span {
                                color: #A7A7A7;
                                font-weight: 500;
                            }
                        }
                        .info-course-name p, .info-user-name p {
                            color: #454545;
                            font-size: 18px;
                            margin-top: 14px;
                        }
                        .info-course-price p {
                            color: #87D96F;
                            font-size: 18px;
                            margin-top: 14px;
                        }
                        .info-user-name {
                            margin-left: 0;
                            margin-right: 40px;
                        }
                    }

                }



            }
        }
    }
}


@media only screen and (max-width : 760px) {
    .payScreen {
        .content {
            main {
                .container {
                    .to-pay-info {
                        .to-pay-info-course {
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            .info-course-name, .info-course-price, .info-user-name {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 500px) {
    .payScreen {
        .content {
            flex-direction: column;
            main {
                .container {
                    .pay-buttons {
                        .pay-buttons-payment {
                            margin-bottom: 10px;
                        }
                    }
                    .to-pay-info {
                        .to-pay-info-course {
                            .presentation {
                                min-width: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 480px) {
.payScreen {
        .content {
            flex-direction: column;
            main {
                .container {
                    .pay-buttons {
                        .pay-buttons-payment {
                            width: 100%;
                            min-width: 100%;
                            max-width: 100%;
                            *:not(button) {
                                max-width: 100%;
                            }
                            button.btn {
                                min-width: 100%;
                                max-width: 100%;
                                padding: 8px;
                                img {
                                    width: 30%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}