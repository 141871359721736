nav {
    height: 8%;
    background-color:black;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    

        .container {
        }

        .container-first {
            .nav-image {
                width: 50px;
            }
            
        }

        .container-second {
            //background-color: red;
            ul {
                display: flex;
                flex-direction: row;
                box-sizing: border-box;
                list-style: none;
                font-size: 14px;
                li {
                    list-style: none;
                    padding: 12px;
                    &:first-child{
                        border-right: 0;
                    }
                    a {
                        color: white;
                        text-decoration: none;
                    }
                }
                .bars {
                    display: none;
                    .menu-header-bars {
                        width: 14px;
                        fill: white;
                    }
                }
            }
        }

        .titlebar-menu {
            width: 100%;
            margin-top: 30px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .container-menu {
                height: 100% !important;
                box-sizing: border-box;
                width: 100%;
                ul {
                    height: 100%;
                    width: 80%;
                    margin: auto;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    box-sizing: border-box;
                    li {
                        opacity: 0.8;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 18px 0;
                        display: flex;
                        flex-direction: row;
                        transition: all 0.3s ease;
                        border-top: 1px solid rgba($color: white, $alpha: 0.4);
                        a {
                            text-decoration: none;
                            color: white;
                        }
                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

}

.titlebar-100 {
    height: 100vh;
    background-color: black !important;
    //animation-name : heightAnimation;
    //animation-duration: 1s;
    z-index: 4;
    ul {
        animation-name: displayAnimation;
        animation-duration: 0.5s;
        color: white;
        li {
            a,span,svg {
                color: white !important;
            }
        }
    }
}

.quit-header-full {
    height: 0vh;
    animation-name : quitHeight; 
    animation-duration: 0.5s;
}

@keyframes heightAnimation {
    0% { height: 0vh; }
    100% { height: 100vh; }
}

@keyframes displayAnimation {
    0% { opacity: 0; }
    30% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes quitHeight {
    0% {height: 100vh;}
    100% {height: 0vh;}
}



@media only screen and (max-width: 720px) {
    nav {
        .container-second {
            ul {
                li.menu-header-el {
                    display: none;
                }
                li.bars {
                    display: block;
                }
            }
        }
    }
}

