.profile {
    position: relative;
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
        main {
            width: 100%;
            height: 100%;
            padding: 30px 0;
            overflow: auto;
            background-color: #FDFDFD;
            .top {
                width: 90%;
                margin: auto;
                margin-bottom: 50px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .screen-title {
                    font-size: 38px;
                }
            }
            
            .container {
                width: 90%;
                margin: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .section {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    //padding-bottom: 40px;
                    margin-bottom: 40px;
                    .section-title {
                        color: #1E7213;
                        font-size: 18px;
                        font-weight: 400;
                    }
                    label {
                        color : #868686;
                        font-size: 14px;
                        margin-bottom: 12px;
                    }
                    .section-content {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .inputRow {
                            display: flex;
                            flex-direction: column;
                            margin: 20px 0;
                            label {
                                color : #868686;
                                font-size: 14px;
                            }
                            input {
                                font-size: 18px;
                                appearance: none;
                                outline:none;
                                padding-top: 12px;
                                padding-bottom: 4px;
                                border: 0;
                                border-bottom: 1px solid #EBEBEB;
                                background-color: transparent;
                            }
                        }
                        .row-50 {
                        width: 45%;
                        }
                        .row-100 {
                            width: 100%;
                            .image-container {
                                width: 100px;
                                height: 100px;
                                overflow: hidden;
                                border-radius: 100%;
                                border: 2px solid #399EDD;
                                img {
                                    width: 100%;
                                    transition: all 0.3s ease;
                                    &:hover {
                                        opacity: 0.7;
                                    }
                                }
                            }
                        }
                    }
                }
                .btn-actualizar-user{
                background-color: #64E36E;
                color: white;
                appearance: none;
                border: 0;
                outline: none;
                font-size: 18px;
                font-weight: 600;
                padding: 12px 32px;
                border-radius: 5px;
                transition: background-color 0.3s ease;
                &:hover {
                    background-color: #2EB739;
                }
                &:active {
                    background-color: #008E0B;
                }
            }
            }
        }
    }   
}

@media only screen and (max-width : 500px) {
    .profile {
        .content {
            flex-direction: column;
            main {
                .container {
                    .section {
                        .section-content {
                            
                                .row-50 {
                                    width: 100%;
                                }
                            
                        }
                    }
                }
            }
        }
    }
}
