.adminCourses {
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
        main {
            width: 100%;
            height: 100%;
            padding: 30px 0;
            overflow: auto;
            background-color: #FDFDFD;
            .top {
                width: 90%;
                margin: auto;
                margin-bottom: 50px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .screen-title {
                    font-size: 38px;
                }
                .button {
                    background-color: #64E36E;
                    border: 0;
                    appearance: none;
                    color: white;
                    font-weight: 500;
                    font-size: 18px;
                    padding: 2px 42px;
                    border-radius: 5px;
                    outline: 0;
                    transition: all 0.3s ease;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:hover{
                        background-color: #00A30D;
                    }
                    &:active {
                        background-color: #3EE692;
                    }
                }
            }
            
            .container {
                width: 90%;
                margin: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .course {
                    width: 100%;
                    background-color: white;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 20px;
                    -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                    -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                    box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                    .course-img {
                        width: 54px;
                        height: 50px;
                        border: 2px solid rgb(58, 100, 237);
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        margin: 0;
                        img {
                            width: 180%;
                        }
                    }
                    .course-title {
                        width: 30%;
                        padding: 0 18px;
                        height: 100%;
                        border-right: 1px solid #DEDEDE;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        text-align: center;
                        span {
                            cursor: default;
                        }
                    }
                    .course-price, .course-duration {
                        width: 15%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        border-right: 1px solid #DEDEDE;
                        span {
                            color: #87D96F;
                            cursor: default;
                        }
                    }
                    .course-actions {
                        width: 44%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        span {
                            color: #6E6E6E;
                            transition: color 0.3s ease;
                            cursor: pointer;
                            &:hover {
                                color: black;
                            }
                            &:active {
                                color: rgb(29, 29, 174);
                            }
                        }
                    }
                }
            }
        }
    }   
}

@media only screen and (max-width : 890px) {
    .adminCourses {
        .content {
            main {
                .container {
                    .course {
                        .course-actions {
                            width: 40%;
                        }
                        .course-duration {
                            width: 10%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 686px) {
    .adminCourses {
        .content {
            main {
                .container {
                    .course {
                        .course-title {
                            width: 40%;
                        }
                        .course-price, .course-duration {
                            display: none;
                        }
                        .course-actions {
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            margin-left: 18px;
                            span {
                                margin: 6px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 540px) {
    .adminCourses {
        .content {
            main {
                .container {
                    .course {
                        .course-title {
                        }
                        .course-price, .course-duration {
                            display: none
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 500px) {
    .adminCourses {
        .content {
            flex-direction: column;
            
        }
    }
}