.sidebar {
    width: 10%;
    max-width: 100px;
    min-width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: #04a64d;
    height: 94vh;
    ul {
        width: 100%;
        margin-top: 30px;
        //background-color: green;
        a {
            text-decoration: none;
            width: 100%;
            margin-bottom: 30px;
            li.selected {
                background-color: #2DC973;
                pointer-events: none;
            }
            li {
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                padding: 20px 0;
                transition: background-color 0.3s ease;
                img {
                    width: 28px;
                }
                .user-photo {
                    border-radius: 100%;
                    border: 3px solid white;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 50px;
                    }
                }
                span {
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: 600;
                    color: white;
                }
                &:hover{
                    background-color: #158447;
                }
                &:active {
                    background-color: #158447;
                }
            }
        }
    }
}


@media only screen and (max-width:760px) {
    .sidebar {
        ul {
            a {
                li {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:500px) {
    .sidebar {
        min-width: 100%;
        height: auto;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        ul {
            margin: 0;
            height: auto;
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: relative;
            a {
                margin: 0;
                padding: 0;
                width: auto;
                margin-bottom: 0px;
                height: 100%;
                li {
                    margin: 0;
                    padding: 20px;
                    border: 2px solid transparent;
                    .user-photo {
                        border: 0;
                        img {
                            width: 28px;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width:292px){
    .sidebar {
        ul {
            a {
                li {
                    padding: 12px;
                }
            }
        }
    }
}

@media only screen and (max-width:224px){
    .sidebar {
        ul {
            overflow-x: auto;
        }
    }
}


/**

https://pay.conekta.com/link/a344e068bc9a481e9115e8650b4a8e6c

*/