.topbar {
    //border: 1px solid red;
    height: 6vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    -webkit-box-shadow: 10px 10px 22px -12px rgba(0,0,0,0.08);
    -moz-box-shadow: 10px 10px 22px -12px rgba(0,0,0,0.08);
    box-shadow: 10px 10px 22px -12px rgba(0,0,0,0.08);
    img {
        width: 50px;
    }
    span {
        cursor: default;
    }
    .logOption {
        display: flex;
        flex-direction: row;
        align-items: center;
        .svg {
            width: 18px;
            margin-right: 5px;
        }
        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
    }
}