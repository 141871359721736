.adminDashboard {
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
    main {
        width: 100%;
        height: 100%;
        padding: 30px 0;
        overflow: auto;
        background-color: #FDFDFD;
        .screen-title {
            width: 90%;
            margin: auto;
            font-size: 38px;
            margin-bottom: 50px;
        }
        .container {
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .dashboard-card {
                width: 25%;
                min-width: 300px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 30px 48px;
                background-color: white;
                -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.1);
                border-radius: 8px;
                margin-right: 40px;
                margin-bottom: 40px;
                .card-title {
                    font-size: 26px;
                    margin-bottom: 8px;
                    width: 100%;
                    text-align: center;
                }
                .card-text {
                    color: #87D96F;
                    font-size: 32px;
                    margin-bottom: 8px;
                }
                .card-button {
                    background-color: #1078E0;
                    text-decoration: none;
                    outline: none;
                    color: white;
                    padding: 8px 24px;
                    margin-bottom: 8px;
                    font-weight: 500;
                    transition: all 0.3s ease;
                    border-radius: 100px;
                    &:hover {
                        background-color: #649ED8;
                    }
                }
            }
        }
    }
}

}

@media only screen and (max-width : 500px) {
    .adminDashboard {
        .content {
            flex-direction: column;
            main {
                .container {
                    .dashboard-card {
                        min-width: 100%;
                        padding-left: 8px;
                        padding-right: 8px;
                        width: 100%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}