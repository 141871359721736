.web-courses {
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        //height: 94vh;
        main.web-courses-main {
            width: 100%;
            height: 100%;
            background-color: #FDFDFD;
            //padding: 30px 0;
            overflow: auto;
            .cover {
                height: 300px;
                background-position: center;
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                .cover-content {
                    text-align: center;
                    .cover-desc {
                        span {
                            font-size: 12px;
                            color: white;
                            letter-spacing: 1px;
                        }
                    }
                    .cover-title {
                        font-size: 32px;
                        color: rgb(255, 255, 255);
                        font-weight: bolder;
                        letter-spacing: 1px;
                        font-weight: bolder;
                    }
                    
                }
                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 80px;
                    opacity: 0.5;
                    padding: 10px;
                }
                .goback {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-decoration: none;
                    color: white;
                    font-size: 18px;
                    margin: 30px;
                    opacity: 0.5;
                    transition: opacity 0.3s ease;
                    img {
                        margin-right: 12px;
                        width: 30px;
                    }
                    &:hover {
                        opacity: 1;
                    }
                    &:active {
                        opacity: 1.3;
                    }
                }
            }
            .container {
                width: 90%;
                margin: 40px auto;
                display: flex;
                flex-direction: column;
                .cursos {
                    margin: 30px 0;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .curso {
                        text-decoration: none;
                        width: 24%;
                        min-width: 240px;
                        max-width: 340px;
                        box-sizing: border-box;
                        margin-right: 20px;
                        margin-bottom: 30px;
                        display: flex;
                        //flex-direction: column;
                        border-radius: 8px;
                        overflow: hidden;
                        transition: all 0.3s ease;
                        -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                        -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                        box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                        &:hover {
                            -webkit-box-shadow: 10px 10px 38px -28px rgba(39, 56, 206, 0.8);
                            -moz-box-shadow: 10px 10px 38px -28px rgba(39, 56, 206, 0.8);
                            box-shadow: 10px 10px 38px -28px rgba(39, 56, 206, 0.8);
                        }
                        .curso-container {
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            .present {
                                width: 100%;
                                padding: 20px;
                                padding-bottom: 50px;
                                background-position: center;
                                background-size: cover;
                                span {
                                    color:white;
                                    font-size: 12px;
                                    letter-spacing: 1px;
                                }
                                p {
                                    font-size: 16px;
                                    color: white;
                                    font-weight: 600;
                                }
                            }
                            .info {
                                background-color: white;
                                padding: 20px;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                span.price {
                                    color:#87D96F ;
                                }
                                h3 {
                                    color: #454545;
                                    font-weight: 400;
                                    margin-bottom: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 924px) {
    .web-courses {
        .content {
            main.web-courses-main {
                .container {
                    .cursos {
                        justify-content: space-between;
                        .curso {
                            //margin:0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 760px) {
    .web-courses {
        .content {
            main.web-courses-main {
                .container {
                    .cursos {
                        .curso {
                            margin:0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 621px) {
    .web-courses {
        .content {
            main.web-courses-main {
                .container {
                    .cursos {
                        justify-content: center;
                        .curso {
                            max-width: 100%;
                            min-width: 100%;
                            width: 100%;
                            margin: 20px 0;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width : 500px) {
    .web-courses {
        .content {
            flex-direction: column;
            main.web-courses-main {
                .container {
                }
            }
        }
    }
}



/*


*/