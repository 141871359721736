.takeCourse {
    position: relative;
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
        main {
            width: 100%;
            height: 100%;
            //padding: 30px 0;
            overflow: auto;
            background-color: #FDFDFD;
            .cover {
                height: 200px;
                background-position: center;
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                .cover-content {
                    border-left: 3px solid white;
                    padding: 14px 20px;
                    .cover-category {
                        span {
                            font-size: 12px;
                            color: white;
                            letter-spacing: 1px;
                        }
                        span.category{
                            letter-spacing: normal;
                            font-size: 18px;
                            color: white;
                            font-weight: 600;
                        }
                    }
                    .cover-title {
                        font-size: 38px;
                        color: white;
                        font-weight: bolder;
                    }
                    
                }
                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 80px;
                    opacity: 0.5;
                    padding: 10px;
                }
                .goback {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-decoration: none;
                    color: white;
                    font-size: 18px;
                    margin: 30px;
                    opacity: 0.5;
                    transition: opacity 0.3s ease;
                    img {
                        margin-right: 12px;
                        width: 30px;
                    }
                    &:hover {
                        opacity: 1;
                    }
                    &:active {
                        opacity: 1.3;
                    }
                }
            }
            .top {
                width: 90%;
                margin: auto;
                margin-bottom: 50px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .screen-title {
                    font-size: 38px;
                }
            }
            .container {
                width: 90%;
                margin: auto;
                display: flex;
                flex-direction: column;
                margin-bottom: 120px;
                .course-content-info {
                    padding: 70px 0;
                    section {
                        margin-bottom: 40px;
                        h2 {
                            color: #4E4D4D;
                            margin-bottom: 18px;
                            font-size: 28px;
                        }
                        p {
                            color: #575757;
                            line-height: 24px;
                        }
                    }
                }
                .course-video {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    outline: none;
                    h2 {
                        color: #4E4D4D;
                        margin-bottom: 18px;
                        font-size: 28px;
                    }
                    .video {
                        width: 100%;
                        outline: none;
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 500px) {
    .takeCourse {
        .content {
            flex-direction: column;
            main {
                .container {
                    
                }
            }
        }
    }
}