.userCourses {
    position: relative;
    .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94vh;
        main {
            width: 100%;
            height: 100%;
            padding: 30px 0;
            overflow: auto;
            background-color: #FDFDFD;
            .top {
                width: 90%;
                margin: auto;
                margin-bottom: 50px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .screen-title {
                    font-size: 38px;
                }
            }
            .container {
                width: 90%;
                margin: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .cursos-user {
                    margin: 30px 0;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .curso-user {
                        text-decoration: none;
                        width: 24%;
                        min-width: 240px;
                        max-width: 340px;
                        box-sizing: border-box;
                        margin-right: 20px;
                        display: flex;
                        flex-direction: column;
                        border-radius: 8px;
                        overflow: hidden;
                        transition: all 0.3s ease;
                        -webkit-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                        -moz-box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                        box-shadow: 10px 10px 38px -28px rgba(0,0,0,0.2);
                        &:hover {
                            -webkit-box-shadow: 10px 10px 38px -28px rgba(39, 56, 206, 0.8);
                            -moz-box-shadow: 10px 10px 38px -28px rgba(39, 56, 206, 0.8);
                            box-shadow: 10px 10px 38px -28px rgba(39, 56, 206, 0.8);
                        }
                        .curso-container-user {
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            .present {
                                width: 100%;
                                padding: 20px;
                                padding-bottom: 50px;
                                background-position: center;
                                background-size: cover;
                                span {
                                    color:white;
                                    font-size: 12px;
                                    letter-spacing: 1px;
                                }
                                p {
                                    font-size: 16px;
                                    color: white;
                                    font-weight: 600;
                                }
                            }
                            .info {
                                background-color: white;
                                padding: 20px;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                span.price {
                                    color:#87D96F ;
                                }
                                h3 {
                                    color: #454545;
                                    font-weight: 400;
                                    margin-bottom: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }   
}

@media only screen and (max-width : 500px) {
    .userCourses {
        .content {
            flex-direction: column;
            main {
                .container {
                }
            }
        }
    }
}
